/**
 * Generated by orval 🍺
 * Do not edit manually.
 * api API
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryClient,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type { EntityType } from '../models/entityType';
import type { EntityTypeCreationResponse } from '../models/entityTypeCreationResponse';
import type { GetDependentAssetCount200 } from '../models/getDependentAssetCount200';
import type { GetEntityTypeParams } from '../models/getEntityTypeParams';
import { executeFetch } from '../../utils/fetch/fetch';
import type { ErrorType } from '../../utils/fetch/fetch';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getEntityType = (
  params?: GetEntityTypeParams,
  options?: SecondParameter<typeof executeFetch>,
  signal?: AbortSignal,
) => {
  return executeFetch<EntityType>(
    { url: `/api/entity-type`, method: 'GET', params, signal },
    options,
  );
};

export const getGetEntityTypeQueryKey = (params?: GetEntityTypeParams) => {
  return [`/api/entity-type`, ...(params ? [params] : [])] as const;
};

export const getGetEntityTypeQueryOptions = <
  TData = Awaited<ReturnType<typeof getEntityType>>,
  TError = ErrorType<unknown>,
>(
  params?: GetEntityTypeParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getEntityType>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetEntityTypeQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEntityType>>> = ({ signal }) =>
    getEntityType(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getEntityType>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetEntityTypeQueryResult = NonNullable<Awaited<ReturnType<typeof getEntityType>>>;
export type GetEntityTypeQueryError = ErrorType<unknown>;

export const useGetEntityType = <
  TData = Awaited<ReturnType<typeof getEntityType>>,
  TError = ErrorType<unknown>,
>(
  params?: GetEntityTypeParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getEntityType>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetEntityTypeQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchGetEntityType = async <
  TData = Awaited<ReturnType<typeof getEntityType>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  params?: GetEntityTypeParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getEntityType>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetEntityTypeQueryOptions(params, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const createEntityType = (
  entityType: EntityType,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<EntityType>(
    {
      url: `/api/entity-type`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: entityType,
    },
    options,
  );
};

export const getCreateEntityTypeMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createEntityType>>,
    TError,
    { data: EntityType },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createEntityType>>,
  TError,
  { data: EntityType },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createEntityType>>,
    { data: EntityType }
  > = (props) => {
    const { data } = props ?? {};

    return createEntityType(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateEntityTypeMutationResult = NonNullable<
  Awaited<ReturnType<typeof createEntityType>>
>;
export type CreateEntityTypeMutationBody = EntityType;
export type CreateEntityTypeMutationError = ErrorType<unknown>;

export const useCreateEntityType = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createEntityType>>,
    TError,
    { data: EntityType },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createEntityType>>,
  TError,
  { data: EntityType },
  TContext
> => {
  const mutationOptions = getCreateEntityTypeMutationOptions(options);

  return useMutation(mutationOptions);
};
export const createEntityTypeAndReturnViolations = (
  entityType: EntityType,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<EntityTypeCreationResponse>(
    {
      url: `/api/entity-type/create`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: entityType,
    },
    options,
  );
};

export const getCreateEntityTypeAndReturnViolationsMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createEntityTypeAndReturnViolations>>,
    TError,
    { data: EntityType },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createEntityTypeAndReturnViolations>>,
  TError,
  { data: EntityType },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createEntityTypeAndReturnViolations>>,
    { data: EntityType }
  > = (props) => {
    const { data } = props ?? {};

    return createEntityTypeAndReturnViolations(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateEntityTypeAndReturnViolationsMutationResult = NonNullable<
  Awaited<ReturnType<typeof createEntityTypeAndReturnViolations>>
>;
export type CreateEntityTypeAndReturnViolationsMutationBody = EntityType;
export type CreateEntityTypeAndReturnViolationsMutationError = ErrorType<unknown>;

export const useCreateEntityTypeAndReturnViolations = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createEntityTypeAndReturnViolations>>,
    TError,
    { data: EntityType },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createEntityTypeAndReturnViolations>>,
  TError,
  { data: EntityType },
  TContext
> => {
  const mutationOptions = getCreateEntityTypeAndReturnViolationsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const deleteEntityType = (id: string, options?: SecondParameter<typeof executeFetch>) => {
  return executeFetch<boolean>(
    { url: `/api/entity-type/delete/${encodeURIComponent(String(id))}`, method: 'POST' },
    options,
  );
};

export const getDeleteEntityTypeMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteEntityType>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteEntityType>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteEntityType>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return deleteEntityType(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteEntityTypeMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteEntityType>>
>;

export type DeleteEntityTypeMutationError = ErrorType<unknown>;

export const useDeleteEntityType = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteEntityType>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteEntityType>>,
  TError,
  { id: string },
  TContext
> => {
  const mutationOptions = getDeleteEntityTypeMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getDependentAssetCount = (
  id: string,
  options?: SecondParameter<typeof executeFetch>,
  signal?: AbortSignal,
) => {
  return executeFetch<GetDependentAssetCount200>(
    {
      url: `/api/entity-type/dependentAssetCount/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getGetDependentAssetCountQueryKey = (id: string) => {
  return [`/api/entity-type/dependentAssetCount/${id}`] as const;
};

export const getGetDependentAssetCountQueryOptions = <
  TData = Awaited<ReturnType<typeof getDependentAssetCount>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getDependentAssetCount>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDependentAssetCountQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDependentAssetCount>>> = ({ signal }) =>
    getDependentAssetCount(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getDependentAssetCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetDependentAssetCountQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDependentAssetCount>>
>;
export type GetDependentAssetCountQueryError = ErrorType<unknown>;

export const useGetDependentAssetCount = <
  TData = Awaited<ReturnType<typeof getDependentAssetCount>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getDependentAssetCount>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetDependentAssetCountQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchGetDependentAssetCount = async <
  TData = Awaited<ReturnType<typeof getDependentAssetCount>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getDependentAssetCount>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetDependentAssetCountQueryOptions(id, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getLoggedInUserEntityType = (
  options?: SecondParameter<typeof executeFetch>,
  signal?: AbortSignal,
) => {
  return executeFetch<EntityType>(
    { url: `/api/entity-type/getLoggedInUser`, method: 'GET', signal },
    options,
  );
};

export const getGetLoggedInUserEntityTypeQueryKey = () => {
  return [`/api/entity-type/getLoggedInUser`] as const;
};

export const getGetLoggedInUserEntityTypeQueryOptions = <
  TData = Awaited<ReturnType<typeof getLoggedInUserEntityType>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getLoggedInUserEntityType>>, TError, TData>
  >;
  request?: SecondParameter<typeof executeFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLoggedInUserEntityTypeQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLoggedInUserEntityType>>> = ({
    signal,
  }) => getLoggedInUserEntityType(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getLoggedInUserEntityType>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetLoggedInUserEntityTypeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLoggedInUserEntityType>>
>;
export type GetLoggedInUserEntityTypeQueryError = ErrorType<unknown>;

export const useGetLoggedInUserEntityType = <
  TData = Awaited<ReturnType<typeof getLoggedInUserEntityType>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getLoggedInUserEntityType>>, TError, TData>
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetLoggedInUserEntityTypeQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchGetLoggedInUserEntityType = async <
  TData = Awaited<ReturnType<typeof getLoggedInUserEntityType>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getLoggedInUserEntityType>>, TError, TData>
    >;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetLoggedInUserEntityTypeQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getPrimaryKeyType = (
  id: string,
  options?: SecondParameter<typeof executeFetch>,
  signal?: AbortSignal,
) => {
  return executeFetch<string>(
    {
      url: `/api/entity-type/primaryKeyType/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getGetPrimaryKeyTypeQueryKey = (id: string) => {
  return [`/api/entity-type/primaryKeyType/${id}`] as const;
};

export const getGetPrimaryKeyTypeQueryOptions = <
  TData = Awaited<ReturnType<typeof getPrimaryKeyType>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPrimaryKeyType>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPrimaryKeyTypeQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPrimaryKeyType>>> = ({ signal }) =>
    getPrimaryKeyType(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPrimaryKeyType>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPrimaryKeyTypeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPrimaryKeyType>>
>;
export type GetPrimaryKeyTypeQueryError = ErrorType<unknown>;

export const useGetPrimaryKeyType = <
  TData = Awaited<ReturnType<typeof getPrimaryKeyType>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPrimaryKeyType>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPrimaryKeyTypeQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const prefetchGetPrimaryKeyType = async <
  TData = Awaited<ReturnType<typeof getPrimaryKeyType>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  id: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPrimaryKeyType>>, TError, TData>>;
    request?: SecondParameter<typeof executeFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getGetPrimaryKeyTypeQueryOptions(id, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const updateEntityType = (
  entityType: EntityType,
  options?: SecondParameter<typeof executeFetch>,
) => {
  return executeFetch<EntityType>(
    {
      url: `/api/entity-type/update`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: entityType,
    },
    options,
  );
};

export const getUpdateEntityTypeMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateEntityType>>,
    TError,
    { data: EntityType },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateEntityType>>,
  TError,
  { data: EntityType },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateEntityType>>,
    { data: EntityType }
  > = (props) => {
    const { data } = props ?? {};

    return updateEntityType(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateEntityTypeMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateEntityType>>
>;
export type UpdateEntityTypeMutationBody = EntityType;
export type UpdateEntityTypeMutationError = ErrorType<unknown>;

export const useUpdateEntityType = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateEntityType>>,
    TError,
    { data: EntityType },
    TContext
  >;
  request?: SecondParameter<typeof executeFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateEntityType>>,
  TError,
  { data: EntityType },
  TContext
> => {
  const mutationOptions = getUpdateEntityTypeMutationOptions(options);

  return useMutation(mutationOptions);
};
