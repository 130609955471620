import isEmpty from 'lodash/isEmpty';
import type { WidgetEmptyState as WidgetEmptyStateProps } from '../../components/WidgetEmptyState/types';

export type KeyValuePairFieldEmptyState = WidgetEmptyStateProps & { showEmptyPair?: boolean };

export type KeyValuePairs = Record<string, unknown>;
export type KeyValuePairList = {
  key: string;
  value: unknown;
  editable?: boolean;
  deletable?: boolean;
}[];

export const adaptToKeyValuePairList = (
  object?: KeyValuePairs,
  addExtraPair = true,
): KeyValuePairList => {
  if (isEmpty(object)) {
    return [];
  }

  let list = Object.entries(object).map(([key, value]) => ({ key, value }));

  // Add an empty pair at the end for better UX
  if (addExtraPair) {
    list = [...list, { key: '', value: '' }];
  }

  return list;
};

export const adaptToKeyValuePairObject = (list?: KeyValuePairList) => {
  if (!list) {
    return undefined;
  }

  return list.reduce((accumulator, { key, value }) => {
    if (key && value) {
      accumulator[key] = value;
    }

    return accumulator;
  }, {});
};
