import { Children, isValidElement } from 'react';
import CreateError from '../../utils/error';

const safeTernaryBoundaryError = new CreateError(
  'No children found in the SafeTernaryBoundary component, this usually means we do not need to use the SafeTernaryBoundary component.',
  'SafeTernaryBoundary',
);

function isAllChildrenNotValid(childrens: ReturnType<typeof Children.toArray>): boolean {
  return childrens.every((child) => {
    const elementChild = child as React.ReactElement;
    if (elementChild.type === SafeTernaryBoundary) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- we are checking for the props
      const nestedGrandKid = elementChild.props?.children?.props?.children as
        | React.ReactElement
        | undefined;

      if (!nestedGrandKid) {
        return true;
      }
      const nestedGrandKids = Children.toArray(nestedGrandKid);
      return isAllChildrenNotValid(nestedGrandKids);
    }

    return !isValidElement(child);
  });
}

// When we have a ternary operator in the JSX, we need to handle the case where the childrens are null.
function SafeTernaryBoundary({ children }: { children: React.ReactElement }) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- we are checking for the props
  const grandKid = children.props?.children as React.ReactElement | undefined;
  if (!grandKid) {
    console.error(safeTernaryBoundaryError);
    return null;
  }

  const grandKidsArray = Children.toArray(grandKid);

  const isAllGrandKidsNull = isAllChildrenNotValid(grandKidsArray);

  if (isAllGrandKidsNull) {
    return null;
  }

  return children;
}

export default SafeTernaryBoundary;
