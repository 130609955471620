import { cva } from 'class-variance-authority';
import type { PillColorsEnum } from './types';

export const pillVariants = cva(
  'border w-fit h-fit border-box flex flex-row shrink-0 justify-center items-center border-secondary',
  {
    variants: {
      variant: {
        soft: '',
        clear: 'border-secondary bg-primary',
        solid: 'text-white border-none',
      },
      color: {
        gray: '',
        brand: '',
        error: '',
        warning: '',
        success: '',
        'blue-light': '',
        blue: '',
        indigo: '',
        purple: '',
        fuchsia: '',
        cyan: '',
      } satisfies Record<PillColorsEnum, string>,
      shape: {
        rounded: 'rounded-full py-xs',
        square: 'rounded-sm py-xxs',
      },
      size: {
        md: '!p-md',
        sm: 'px-md',
        xs: 'px-sm',
      },
      disabled: {
        true: '!bg-disabled !text-disabled',
      },
    },
    compoundVariants: [
      {
        variant: 'soft',
        color: 'gray',
        className: 'border-utility-gray-200 bg-utility-gray-50 text-utility-gray-700',
      },
      {
        variant: 'soft',
        color: 'brand',
        className: 'border-utility-brand-200 bg-utility-brand-50 text-utility-brand-700',
      },
      {
        variant: 'soft',
        color: 'error',
        className: 'border-utility-error-200 bg-utility-error-50 text-utility-error-700',
      },
      {
        variant: 'soft',
        color: 'warning',
        className: 'border-utility-warning-200 bg-utility-warning-50 text-utility-warning-700',
      },
      {
        variant: 'soft',
        color: 'success',
        className: 'border-utility-success-200 bg-utility-success-50 text-utility-success-700',
      },
      {
        variant: 'soft',
        color: 'blue-light',
        className:
          'border-utility-blue-light-200 bg-utility-blue-light-50 text-utility-blue-light-700',
      },
      {
        variant: 'soft',
        color: 'blue',
        className: 'border-utility-blue-200 bg-utility-blue-50 text-utility-blue-700',
      },
      {
        variant: 'soft',
        color: 'indigo',
        className: 'border-utility-indigo-200 bg-utility-indigo-50 text-utility-indigo-700',
      },
      {
        variant: 'soft',
        color: 'purple',
        className: 'border-utility-purple-200 bg-utility-purple-50 text-utility-purple-700',
      },
      {
        variant: 'soft',
        color: 'fuchsia',
        className: 'border-utility-fuchsia-200 bg-utility-fuchsia-50 text-utility-fuchsia-700',
      },
      {
        variant: 'soft',
        color: 'cyan',
        className: 'border-utility-cyan-200 bg-utility-cyan-50 text-utility-cyan-700',
      },
      {
        variant: 'clear',
        color: 'brand',
        className: 'text-utility-brand-700',
      },
      {
        variant: 'clear',
        color: 'gray',
        className: 'text-utility-gray-700',
      },
      {
        variant: 'clear',
        color: 'error',
        className: 'text-utility-error-700',
      },
      {
        variant: 'clear',
        color: 'warning',
        className: 'text-utility-warning-700',
      },
      {
        variant: 'clear',
        color: 'success',
        className: 'text-utility-success-700',
      },
      {
        variant: 'clear',
        color: 'blue-light',
        className: 'text-utility-blue-light-700',
      },
      {
        variant: 'clear',
        color: 'blue',
        className: 'text-utility-blue-700',
      },
      {
        variant: 'clear',
        color: 'indigo',
        className: 'text-utility-indigo-700',
      },
      {
        variant: 'clear',
        color: 'purple',
        className: 'text-utility-purple-700',
      },
      {
        variant: 'clear',
        color: 'fuchsia',
        className: 'text-utility-fuchsia-700',
      },
      {
        variant: 'clear',
        color: 'cyan',
        className: 'text-utility-cyan-700',
      },
      {
        variant: 'solid',
        color: 'brand',
        className: 'bg-utility-brand-600',
      },
      {
        variant: 'solid',
        color: 'gray',
        className: 'bg-utility-gray-600',
      },
      {
        variant: 'solid',
        color: 'error',
        className: 'bg-utility-error-600',
      },
      {
        variant: 'solid',
        color: 'warning',
        className: 'bg-utility-warning-600',
      },
      {
        variant: 'solid',
        color: 'success',
        className: 'bg-utility-success-600',
      },
      {
        variant: 'solid',
        color: 'blue-light',
        className: 'bg-utility-blue-light-600',
      },
      {
        variant: 'solid',
        color: 'blue',
        className: 'bg-utility-blue-600',
      },
      {
        variant: 'solid',
        color: 'indigo',
        className: 'bg-utility-indigo-600',
      },
      {
        variant: 'solid',
        color: 'purple',
        className: 'bg-utility-purple-600',
      },
      {
        variant: 'solid',
        color: 'fuchsia',
        className: 'bg-utility-fuchsia-600',
      },
      {
        variant: 'solid',
        color: 'cyan',
        className: 'bg-utility-cyan-600',
      },
    ],
    defaultVariants: {},
  },
);

export const labelVariants = cva('flex', {
  variants: {
    variant: {
      soft: '',
      clear: '',
      solid: '',
    },
  },
});

export const dotVariants = cva('w-md h-md rounded-full', {
  variants: {
    variant: {
      soft: '',
      clear: '',
      solid: '!bg-primary',
    },
    color: {
      gray: 'bg-utility-gray-500',
      brand: 'bg-utility-brand-500',
      error: 'bg-utility-error-500',
      warning: 'bg-utility-warning-500',
      success: 'bg-utility-success-500',
      'blue-light': 'bg-utility-blue-light-500',
      blue: 'bg-utility-blue-500',
      indigo: 'bg-utility-indigo-500',
      purple: 'bg-utility-purple-500',
      fuchsia: 'bg-utility-fuchsia-500',
      cyan: 'bg-utility-cyan-500',
    } satisfies Record<PillColorsEnum, string>,
  },
  compoundVariants: [
    { variant: 'soft', color: 'gray', className: 'bg-utility-gray-500' },
    { variant: 'soft', color: 'brand', className: 'bg-utility-brand-500' },
    { variant: 'soft', color: 'error', className: 'bg-utility-error-500' },
    { variant: 'soft', color: 'warning', className: 'bg-utility-warning-500' },
    { variant: 'soft', color: 'success', className: 'bg-utility-success-500' },
    { variant: 'soft', color: 'blue-light', className: 'bg-utility-blue-light-500' },
    { variant: 'soft', color: 'blue', className: 'bg-utility-blue-500' },
    { variant: 'soft', color: 'indigo', className: 'bg-utility-indigo-500' },
    { variant: 'soft', color: 'purple', className: 'bg-utility-purple-500' },
    { variant: 'soft', color: 'fuchsia', className: 'bg-utility-fuchsia-500' },
    { variant: 'soft', color: 'cyan', className: 'bg-utility-cyan-500' },
    { variant: 'clear', color: 'gray', className: 'bg-utility-gray-500' },
    { variant: 'clear', color: 'brand', className: 'bg-utility-brand-500' },
    { variant: 'clear', color: 'error', className: 'bg-utility-error-500' },
    { variant: 'clear', color: 'warning', className: 'bg-utility-warning-500' },
    { variant: 'clear', color: 'success', className: 'bg-utility-success-500' },
    { variant: 'clear', color: 'blue-light', className: 'bg-utility-blue-light-500' },
    { variant: 'clear', color: 'blue', className: 'bg-utility-blue-500' },
    { variant: 'clear', color: 'indigo', className: 'bg-utility-indigo-500' },
    { variant: 'clear', color: 'purple', className: 'bg-utility-purple-500' },
    { variant: 'clear', color: 'fuchsia', className: 'bg-utility-fuchsia-500' },
    { variant: 'clear', color: 'cyan', className: 'bg-utility-cyan-500' },
  ],
});

export const decoratorVariants = cva('', {
  variants: {
    variant: {
      soft: '',
      clear: '',
      solid: 'text-white',
    },
    color: {
      gray: 'text-utility-gray-500',
      brand: 'text-utility-brand-500',
      error: 'text-utility-error-500',
      warning: 'text-utility-warning-500',
      success: 'text-utility-success-500',
      'blue-light': 'text-utility-blue-light-500',
      blue: 'text-utility-blue-500',
      indigo: 'text-utility-indigo-500',
      purple: 'text-utility-purple-500',
      fuchsia: 'text-utility-fuchsia-500',
      cyan: 'text-utility-cyan-500',
    } satisfies Record<PillColorsEnum, string>,
    size: {
      md: 'w-xl h-xl',
      sm: 'w-lg h-lg',
      xs: 'w-lg h-lg',
    },
  },
});

export const iconVariants = cva('', {
  variants: {
    color: {
      gray: 'stroke-utility-gray-500',
      brand: 'stroke-utility-brand-500',
      error: 'stroke-utility-error-500',
      warning: 'stroke-utility-warning-500',
      success: 'stroke-utility-success-500',
      'blue-light': 'stroke-utility-blue-light-500',
      blue: 'stroke-utility-blue-500',
      indigo: 'stroke-utility-indigo-500',
      purple: 'stroke-utility-purple-500',
      fuchsia: 'stroke-utility-fuchsia-500',
      cyan: 'stroke-utility-cyan-500',
    } satisfies Record<PillColorsEnum, string>,
  },
});
